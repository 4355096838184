import React from 'react';

//styles
import classes from './modal.module.css';
import { FiX, FiXOctagon } from 'react-icons/fi';

function BlockedModal({close}) {
    return (
        <div className={classes.backdrop}>
            <div className={classes.backdrop_main}>
                {/* <div className={classes.backdrop_close}><FiX /></div> */}
                <div className={classes.done_container}>
                    <FiXOctagon color='#ff0000' size={70}/>
                    <h1 style={{fontSize: 20, fontWeight: 500}}>Withdrwal blocked for this account</h1>
                    <p style={{fontSize: 16, fontWeight: 400}}>Whtidrawal for this account has been blocked, contact support for more details</p>
                </div>
                <button onClick={close}>Dismiss</button>
            </div>
        </div>
    )
}

export default BlockedModal