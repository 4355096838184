//images
import founder from '../assets/founder.JPG';
import headquaters from '../assets/headquaters.png';
import agents from '../assets/agents.JPG';

const team = [
    {name: 'Secure Income Brokers', img: headquaters, writeup: 'Here at Secure Invome Brokers, we are dedicated to professionally help manage your financial decisions to ensure maximum profit.'},
    {name: 'Gen. Valery H. Leonid', img: founder, writeup: 'Director, Secure Income Profits LLC'},
    {name: 'Out Top Agents', img: agents, writeup: 'There is more to achieve with secureincomebrokers.com our algorithm works on your investment and makes profits automatically for all investments available'},
];

export default team;